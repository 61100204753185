import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getVideoIdFromAlias(alias) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/videos/id/${alias}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function getVideoAliasFromId(id) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/videos/alias/${id}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export async function getQumuSources(qumuId) {
  const url = `https://video-toyota.qumucloud.com/api/2.2/rest/widgets/${qumuId}.json`;
  const response = await fetch(url);
  
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  
  const data = await response.json();
  
  if (!data.kulus || data.kulus.length === 0) {
    throw new Error('No kulu data available');
  }
  
  const kulu = data.kulus[0];
  const { guid, title, duration, player, thumbnail, media } = kulu;
  
  // Find the media variants with the specified format codes
  const mobileVariant = media?.variants?.find(variant => variant.formatCode === "mobile_mp4");
  const hlsVariant = media?.variants?.find(variant => variant.formatCode === "hls_default");
  const canonicalVariant = media?.variants?.find(variant => variant.formatCode === "canonical");
  
  return {
    id: guid,
    title,
    duration,
    shareLink: player,
    poster: thumbnail?.url,
    mobileSrc: mobileVariant?.url,
    hlsSrc: hlsVariant?.url,
    canonicalSrc: canonicalVariant?.url,
  };
}